import React, {
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/reducers";
import { setInsuranceCategory } from "@redux/insurancesupersaver/actions";
import ButtonArrow from "../buttonArrow";
import { useRouter } from "next/router";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useDomainContext } from "@hooks/useDomainContext";
import ListingFilters from "@components/shared/listingFilters";
import {
    initiateQuestionnaireQuestions,
    setQuestionValue,
} from "@redux/shared/actions";
import { arrayToKeyedObject } from "@lib/sharedUtils";
import { questions } from "@components/shared/listingFilters/questions";

export default function SellingBox(): ReactElement {
    const dispatch = useDispatch();
    const { domain, category: sellingBoxCategory } = useDomainContext();
    const categories = domain?.categories;
    const [error, setError] = useState(false);

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const router = useRouter();

    const { insuranceCategory } = useSelector(
        (state: RootState) => state.rootReducer.insurancesupersaver.sellingBox,
    );

    const { questions: filters } = useSelector(
        (state: RootState) => state.rootReducer.shared.listing,
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const gettingFilters = () => {
            //@ts-ignore
            const finalFilters: { [x: string]: string } | null = {};
            Object.keys(filters).forEach((filterKey) => {
                const value = filters[filterKey]?.value;
                const name = filters[filterKey]?.name;

                if (!value) {
                    return;
                }

                finalFilters[name] = value;
            });

            return btoa(JSON.stringify(finalFilters));
        };

        if (filters["zipCode"]?.value.length >= 4) {
            const zipCode = filters["zipCode"]?.value;
            hiddenInputRef.current?.focus();
            const formattedZipCode =
                zipCode.length === 4 ? `0${zipCode}` : zipCode;

            window.location.href = `${window.location.origin}/${
                sellingBoxCategory?.slugAlias ??
                (insuranceCategory?.slugAlias as string)
            }/offers?zc=${formattedZipCode}&filters=${gettingFilters()}&referrer=${
                sellingBoxCategory?.slugAlias ?? "home"
            }&${gettingQueriesAsString(router.query, "inline", ["category"])}`;
        } else {
            setError(true);
        }
    };

    const checkZipCode = () => {
        if (localStorage.getItem("filters")) {
            dispatch(
                setQuestionValue({
                    name: "zipCode",
                    value:
                        (
                            JSON.parse(
                                localStorage.getItem("filters") as string,
                            ) as {
                                [x: string]: string;
                            }
                        ).zipCode ?? "",
                }),
            );
        }
        setTimeout(() => {
            document.getElementsByName("zipCode")[0]?.focus();
        }, 100);
    };

    useEffect(() => {
        dispatch(
            initiateQuestionnaireQuestions(
                arrayToKeyedObject(
                    questions[
                        sellingBoxCategory
                            ? sellingBoxCategory.slugAlias
                            : (insuranceCategory?.slugAlias as string)
                    ].filter((question) => question.isInHomePage),
                    "name",
                ),
            ),
        );
        if (insuranceCategory) checkZipCode();
    }, [insuranceCategory]);

    useEffect(() => {
        checkZipCode();
    }, []);

    return (
        <>
            <div className={styles["selling-box"]}>
                {!sellingBoxCategory && (
                    <div className={styles["insurance-type"]}>
                        <div className={styles["insurance-radio"]}>
                            {categories?.map((categoryItem, index) => (
                                <div
                                    className={styles["radio-item"]}
                                    key={index}
                                >
                                    <input
                                        id={categoryItem.slug}
                                        type="radio"
                                        value={categoryItem.slug}
                                        className="hidden"
                                        name="insurance-category"
                                        onChange={(e) => {
                                            dispatch(
                                                setInsuranceCategory(
                                                    categories.find(
                                                        (catItem) =>
                                                            catItem.slug ===
                                                            e.target.value,
                                                    ) ?? null,
                                                ),
                                            );
                                        }}
                                        checked={
                                            insuranceCategory?.slug ===
                                            categoryItem?.slug
                                        }
                                    />
                                    <label htmlFor={categoryItem.slug}>
                                        <span className={styles["icon"]}>
                                            <img
                                                src={
                                                    categoryItem?.icon?.fullPath
                                                }
                                                alt={`${categoryItem.name} icon`}
                                            />
                                        </span>
                                        <span className={styles["title"]}>
                                            {categoryItem.slugAlias}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <form className={styles["form"]} onSubmit={handleSubmit}>
                    <ListingFilters
                        className={styles["filters"]}
                        selectQuestionClassName={styles["select-question"]}
                        toggleQuestionClassName={styles["toggle-question"]}
                        textQuestionClassName={styles["text-question"]}
                        rowClassName={styles["row"]}
                        isQuestionSameRow={true}
                        noMinimize
                    />
                    <div className={styles["button"]}>
                        <button type="submit" id="viewRates">
                            View{" "}
                            {sellingBoxCategory?.slugAlias === "medicare"
                                ? "Plans"
                                : "Rates"}
                            <ButtonArrow />
                        </button>
                    </div>
                    {error && (
                        <span className={styles["error"]}>
                            Please enter a valid zipCode
                        </span>
                    )}
                </form>
            </div>
            <div>
                <input
                    type="number"
                    ref={hiddenInputRef}
                    className="select-none opacity-0 absolute -top-48 pointer-events-none"
                    autoComplete="off"
                />
            </div>
        </>
    );
}
