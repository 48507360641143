import { FilterQuestion } from "@lib/shared/types";
import { RootState } from "@redux/reducers";
import { setQuestionValue } from "@redux/shared/actions";
import { useRouter } from "next/router";
import React, {
    ReactElement,
    useEffect,
    useRef,
    useState,
    Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { handleFieldMasking } from "@lib/shared/form";
let valuesHasBeenSetFromQuery = false;

export default function ListingFilters({
    handleSubmit,
    submitButtonText,
    submitButtonStyle,
    className,
    selectQuestionClassName,
    toggleQuestionClassName,
    textQuestionClassName,
    rowClassName,
    questionsClassName,
    toggleButtonClassName,
    isQuestionSameRow = false,
    noMinimize = false,
    fireSubmitOnEnterKeyWord = false,
}: {
    handleSubmit?: () => void;
    submitButtonText?: string;
    submitButtonStyle?: string;
    className?: string;
    selectQuestionClassName?: string;
    toggleQuestionClassName?: string;
    textQuestionClassName?: string;
    rowClassName?: string;
    questionsClassName?: string;
    isQuestionSameRow?: boolean;
    noMinimize?: boolean;
    toggleButtonClassName?: string;
    fireSubmitOnEnterKeyWord?: boolean;
}): ReactElement {
    const dispatch = useDispatch();

    const [boxHeight, setBoxHeight] = React.useState(0);
    const [active, setActive] = useState(false);
    const boxRef = useRef<HTMLDivElement | null>(null);
    const router = useRouter();

    const { questions: listingQuestions } = useSelector(
        (state: RootState) => state.rootReducer.shared.listing,
    );

    const handleQuestionOnChange = (name: string, value: string) => {
        let newVal = value;
        if (listingQuestions[name].mask === "zipCode") {
            newVal = handleFieldMasking(listingQuestions[name].mask, value);
        }

        dispatch(setQuestionValue({ name, value: newVal }));
    };

    const gettingQuestion = (question: FilterQuestion, key: number) => {
        switch (question.type) {
            case "select":
                return (
                    <SelectQuestion
                        question={question}
                        onChange={handleQuestionOnChange}
                        value={listingQuestions[question.name]?.value}
                        key={key}
                        className={selectQuestionClassName}
                    />
                );
            case "toggle":
                return (
                    <ToggleQuestion
                        question={question}
                        onChange={handleQuestionOnChange}
                        key={key}
                        className={toggleQuestionClassName}
                    />
                );
            case "text":
                return (
                    <TextQuestion
                        question={question}
                        onChange={handleQuestionOnChange}
                        key={key}
                        className={textQuestionClassName}
                        handleSubmit={handleSubmit}
                        fireSubmitOnEnterKeyWord={fireSubmitOnEnterKeyWord}
                    />
                );
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setBoxHeight(boxRef.current?.scrollHeight ?? 0);
        }, 1000);
    }, []);

    useEffect(() => {
        if (
            Object.keys(listingQuestions).length &&
            !valuesHasBeenSetFromQuery
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const filters: { [x: string]: string } = router.query.filters
                ? JSON.parse(atob(router.query.filters as string))
                : localStorage?.getItem("filters")
                ? JSON.parse(localStorage.getItem("filters") as string)
                : null;

            if (filters) {
                Object.keys(filters).forEach((filterKey) => {
                    if (listingQuestions[filterKey]) {
                        dispatch(
                            setQuestionValue({
                                name: filterKey,
                                value: filters[filterKey],
                            }),
                        );
                    }
                });

                valuesHasBeenSetFromQuery = true;
            }
        }
    }, [listingQuestions]);

    return (
        <div
            className={`${styles["listing-filters"]} ${className ?? ""}`}
            id="listing-filters"
        >
            {!noMinimize && (
                <div
                    className={`${styles["toggle-button"]} ${
                        toggleButtonClassName ? toggleButtonClassName : ""
                    }`}
                >
                    <button onClick={() => setActive(!active)}>
                        {active ? "Hide" : "Show"} Filters
                    </button>
                </div>
            )}
            <div
                ref={boxRef}
                style={{
                    height: !noMinimize ? (active ? boxHeight : 0) : undefined,
                    opacity: !noMinimize ? (active ? 1 : 0) : undefined,
                    marginBottom: !noMinimize
                        ? active
                            ? "1rem"
                            : 0
                        : undefined,
                }}
                className={styles["inner"]}
            >
                <div
                    className={`${styles["questions"]} ${
                        questionsClassName as string
                    }`}
                >
                    {!isQuestionSameRow && (
                        <div
                            className={`${styles["row"]} ${rowClassName ?? ""}`}
                        >
                            {Object.keys(listingQuestions).map(
                                (questionKey, index) => (
                                    <Fragment key={index}>
                                        {listingQuestions[questionKey].type ===
                                            "text" &&
                                            gettingQuestion(
                                                listingQuestions[questionKey],
                                                index,
                                            )}
                                    </Fragment>
                                ),
                            )}
                        </div>
                    )}
                    <div
                        className={`${styles["row"]} ${
                            !isQuestionSameRow ? styles["boxes"] : ""
                        } ${rowClassName ?? ""}`}
                        style={{
                            width: isQuestionSameRow ? "100%" : undefined,
                        }}
                    >
                        {Object.keys(listingQuestions).map(
                            (questionKey, index) => (
                                <Fragment key={index}>
                                    {isQuestionSameRow
                                        ? gettingQuestion(
                                              listingQuestions[questionKey],
                                              index,
                                          )
                                        : listingQuestions[questionKey].type ===
                                              "select" &&
                                          gettingQuestion(
                                              listingQuestions[questionKey],
                                              index,
                                          )}
                                </Fragment>
                            ),
                        )}
                    </div>
                    {!isQuestionSameRow && (
                        <div
                            className={`${styles["row"]} ${rowClassName ?? ""}`}
                        >
                            {Object.keys(listingQuestions).map(
                                (questionKey, index) => (
                                    <Fragment key={index}>
                                        {listingQuestions[questionKey].type ===
                                            "toggle" &&
                                            gettingQuestion(
                                                listingQuestions[questionKey],
                                                index,
                                            )}
                                    </Fragment>
                                ),
                            )}
                        </div>
                    )}

                    {handleSubmit && (
                        <div
                            className={`${styles["submit"]} ${
                                submitButtonStyle ?? ""
                            }`}
                        >
                            <button type="button" onClick={handleSubmit}>
                                {submitButtonText ?? "Update"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const TextQuestion = ({
    question,
    onChange,
    className,
    handleSubmit,
    fireSubmitOnEnterKeyWord = false,
}: {
    question: FilterQuestion;
    onChange: (name: string, value: string) => void;
    className?: string;
    handleSubmit?: () => void;
    fireSubmitOnEnterKeyWord?: boolean;
}) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && fireSubmitOnEnterKeyWord && handleSubmit) {
            event.preventDefault();
            handleSubmit();
        }
    };
    return (
        <div
            id={styles[`${question.name}`]}
            className={`${styles["text-question"]} ${styles["question"]} ${
                className ?? ""
            }`}
        >
            <input
                type={question.fieldType}
                name={question.name}
                value={question.value}
                onChange={(e) => onChange(question.name, e.target.value)}
                id={question.name}
                placeholder={question.placeholder}
                maxLength={question.maxLength}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

const ToggleQuestion = ({
    question,
    onChange,
    className,
}: {
    question: FilterQuestion;
    onChange: (name: string, value: string) => void;
    className?: string;
}) => {
    return (
        <div
            className={`${styles["toggle-question"]} ${styles["question"]} ${
                className ?? ""
            }`}
        >
            <input
                type="checkbox"
                name={question.name}
                value={question.value}
                onChange={(e) =>
                    onChange(question.name, e.target.checked ? "1" : "0")
                }
                id={question.name}
                checked={question.value === "1" ? true : false}
            />
            <label htmlFor={question.name}>
                <span>{question.label}</span>
                <span className={styles["checkbox"]}>
                    <svg viewBox="0,0,50,50">
                        <path d="M5 30 L 20 45 L 45 5"></path>
                    </svg>
                </span>
            </label>
        </div>
    );
};

const SelectQuestion = ({
    question,
    onChange,
    value,
    className,
}: {
    question: FilterQuestion;
    onChange: (name: string, value: string) => void;
    value: string;
    className?: string;
}) => {
    const gettingOptionLabel = (value: string) => {
        const activeOption =
            question.options?.filter((option) => {
                return option.value === value;
            }) || [];

        return activeOption[0] ? activeOption[0].label : question.placeholder;
    };

    return (
        <div
            id={styles[`${question.name}`]}
            className={`${styles["select-question"]} ${styles["question"]} ${
                className ?? ""
            }`}
        >
            <label>{question.label}</label>
            <span className={styles["value"]}>
                {gettingOptionLabel(question.value)}
            </span>
            <select
                name={question.name}
                value={
                    !value
                        ? "placeholder"
                        : question.name === "birthDate"
                        ? value.split("-")[0]
                        : value
                }
                onChange={(e) => onChange(question.name, e.target.value)}
            >
                {question.placeholder && (
                    <option value="placeholder" disabled>
                        {question.placeholder}
                    </option>
                )}
                {question.options?.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};
